<script setup lang="ts">
const { isSuperadmin, isAdmin } = useUserState()
const { languageStrings } = useAppConfig()
</script>

<template>
  <v-list class="!p-0">
    <v-divider />
    <v-list-group>
      <template v-slot:activator="{ props }">
        <v-list-item
          v-bind="props"
          prepend-icon="$mdiCog"
          title="Configuración"
        />
      </template>
      <v-divider v-if="isSuperadmin || isAdmin" />
      <v-list-item v-if="isSuperadmin || isAdmin" title="Productos" prepend-icon="$mdiProducts" :to="isSuperadmin || isAdmin ? '/config/products' : '/'" :disabled="!isSuperadmin && !isAdmin" />
      <v-divider v-if="isSuperadmin || isAdmin" />
      <v-list-item v-if="isSuperadmin || isAdmin" :title="languageStrings.companies" prepend-icon="$mdiAccountTie" :to="isSuperadmin || isAdmin ? '/config/companies' : '/'" :disabled="!isSuperadmin && !isAdmin" />
      <v-divider />
      <v-list-item title="Documentación" prepend-icon="$mdiDocuments" to="/config/documentation" />
      <v-divider v-if="isSuperadmin || isAdmin" />
      <v-list-item v-if="isSuperadmin || isAdmin" title="Usuarios" prepend-icon="$mdiAccount" :to="isSuperadmin || isAdmin ? '/config/users' : '/'" :disabled="!isSuperadmin && !isAdmin" />
      <v-divider v-if="isSuperadmin || isAdmin" />
      <v-list-item v-if="isSuperadmin || isAdmin" title="Facturas" prepend-icon="$mdiInvoice" :to="isSuperadmin || isAdmin ? '/config/invoices' : '/'" :disabled="!isSuperadmin && !isAdmin" />
    </v-list-group>
  </v-list>
</template>